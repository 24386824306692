import { BrowserRouter, Routes, Route } from "react-router-dom";

import React from "react";
import HomePage from "../pages/HomePage";
import RegistrationSuccessful from "../pages/RegistrationSuccessful";
import RegistrationDashboard from "../pages/RegistrationDashboard";
import JambStaffDataUpdate from "../pages/JambStaffDataUpdate";
import NotFound from "../pages/NotFound";
import ApprovalPage from "../pages/ApprovalPage";
import ViewParticipant from "../pages/ViewParticipant";
import VirtualMail from "../pages/VirtualMail";
import Accreditation from "../pages/Accreditation";

function MainRoutes() {
  const routes = [
    { path: "/", component: HomePage },
    { path: "/virtual", component: VirtualMail },
    { path: "/registrationsuccessful", component: RegistrationSuccessful },
    { path: "/dashboard", component: RegistrationDashboard },
    { path: "/jambstaff/:id", component: JambStaffDataUpdate },
    { path: "/approval", component: ApprovalPage },
    { path: "/participant/:id", component: ViewParticipant },
    { path: "/accreditation", component: Accreditation },
    { path: "*", component: NotFound },
  ];
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((c, i) => (
          <Route key={i} path={c.path} element={<c.component />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default MainRoutes;
