import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { AlertContext } from "./context/AlertContext";
import { MySnackBarContext } from "./components/MySnackBar";
import MainRoutes from "./routes/MainRoutes";
function App() {
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });

  return (
    <AlertContext.Provider value={{ alertData, setAlertData }}>
      <MainRoutes />
      <MySnackBarContext alertData={alertData} setAlertData={setAlertData} />
    </AlertContext.Provider>
    // <div>
    //   <HomePage />
    // </div>
  );
}

export default App;
