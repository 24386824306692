import React, { useContext, useEffect, useState } from "react";
import { httpService } from "../httpService";
import { Badge, Table } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, IconButton } from "@mui/material";
import { Mail, Send } from "@mui/icons-material";
import { AlertContext } from "../context/AlertContext";

function VirtualMail() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const virtual = async () => {
    setLoading(true);

    const { data } = await httpService.get(
      "participant/dashboardv2?attendanceMode=virtual"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    virtual();
  }, []);
  return (
    <div>
      <div className="p-3">
        <h4>SEND VIRTUAL LINKS</h4>
        {loading && <CircularProgress />}
        <Table striped borderless>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email Address</th>
              <th>Attendance Mode</th>
              <th>Send Mail</th>
            </tr>
          </thead>
          <tbody>
            {results.map((c, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td className="text-capitalize">
                  {c.firstName} {c.surname}
                </td>
                <td>
                  {c.code}
                  {c.phoneNumber}
                </td>
                <td>{c.emailAddress}</td>
                <td>
                  <Badge>{c.attendanceMode}</Badge>
                </td>
                <td>
                  <SendMail participant={c} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default VirtualMail;

function SendMail({ participant }) {
  const { setAlertData } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const hasBeenSent = async () => {
    setLoading(true);
    const { data } = await httpService(
      `approval/hasbeensentzoom/${participant._id}`
    );

    if (data) setSent(true);

    setLoading(false);
  };

  useEffect(() => {
    hasBeenSent();
  }, []);
  const sendMail = async () => {
    setLoading(true);
    const { data, error } = await httpService.post(
      "approval/sendzoomlink",
      participant
    );

    if (data) {
      hasBeenSent();
      setAlertData({ open: true, message: data, severity: "success" });
    }

    if (error) {
      setAlertData({ open: true, message: error, severity: "error" });
    }

    setLoading(false);
  };
  return (
    <IconButton color="primary" disabled={sent} onClick={sendMail}>
      {loading ? <CircularProgress size={15} /> : <Send />}
    </IconButton>
  );
}
