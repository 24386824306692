import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import logo from "./logo.png";
function RegistrationSuccessful() {
  return (
    <div>
      <div className="mt-5 container">
        <div className="mb-5">
          <div className="text-center">
            <img src={logo} height={100} width={120} alt="logo" />

            <div className="mt-2">
              <h3>JAMB EQUAL OPPORTUNITY GROUP CONFERENCE 2024</h3>
            </div>
          </div>
        </div>
        <Alert>
          <AlertTitle>REGISTRATION SUCCESSFUL</AlertTitle>
          Thank you for indicating your interest in attending the 1st Africa
          Regional Conference on Equal Access to Higher Education. We are glad
          to inform you that your request for Virtual Participation in the
          Conference has been approved. We will share the link for you to join
          virtually. Thank you for your interest in promoting inclusivity in
          higher education
        </Alert>
      </div>
    </div>
  );
}

export default RegistrationSuccessful;
