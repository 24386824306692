import React from "react";
import { subThemes } from "../dataTexts";
import { Typography } from "@mui/material";

function JeogSubthemes() {
  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <div className="container pt-5 pb-5 mb-5">
        <div className="d-flex justify-content-end">
          <div className="col-lg-6 mb-5 border-bottom">
            <div className="d-none d-lg-block">
              <p className="ourAim">Sub Themes</p>
            </div>
            <div className="d-sm-block d-lg-none">
              <p className="ourAimMobile">Sub Themes</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center flex-wrap">
          {subThemes.map((c, i) => (
            <div className="col-lg-3 mb-3   rounded text-center shadow-sm p-3 mb-3 me-3">
              <h4 style={{ color: "#142d4c" }}>{i + 1}.</h4>
              <Typography color={"#385170"}>{c}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JeogSubthemes;
