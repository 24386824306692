import { LoadingButton } from "@mui/lab";
import React, { useContext, useEffect, useState } from "react";
import { httpService } from "../httpService";
import { AlertContext } from "../context/AlertContext";
import { Modal, Table } from "react-bootstrap";

function Accreditation() {
  const [otp, setOtp] = useState("");
  const { setAlertData } = useContext(AlertContext);
  const [participant, setParticipant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [accredited, setAllAccredited] = useState([]);
  const [searched, setSearched] = useState([]);
  const [searching, setSearching] = useState(false);
  const retrieve = async () => {
    setLoading(true);
    const { data, error } = await httpService.post(
      "accredit/retrieveparticipant",
      { otp }
    );

    if (data) {
      setParticipant(data);
    }

    if (error) {
      setAlertData({ severity: "error", message: error, open: true });
    }
    setLoading(false);
  };

  const accreditParticipant = async () => {
    setLoading(true);
    const { data, error } = await httpService.post(`accredit/accredit`, {
      participant: participant.participant._id,
      otp: participant.otp,
    });

    if (data) {
      allAccredited();
      setParticipant(null);
      setAlertData({ severity: "success", message: data, open: true });
    }

    if (error) {
      setAlertData({ severity: "error", message: error, open: true });
    }
    setLoading(false);
  };

  const allAccredited = async () => {
    setLoading(true);
    const { data } = await httpService("accredit/allacredited");
    if (data) {
      setAllAccredited(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    allAccredited();
  }, []);

  const searchParticipant = async () => {
    setSearching(true);

    const { data } = await httpService.post("accredit/search", {
      value: searchData,
    });
    if (data) setSearched(data);

    setSearching(false);
  };
  return (
    <div>
      <div className="p-3 mt-5">
        <div className="row">
          <div className="col-lg-7">
            <div className="">
              <div className="mb-3">
                <h4>ACCREDITATION</h4>
              </div>
              <div className="col-lg-4">
                <input
                  onChange={(e) => setOtp(e.target.value)}
                  type="text"
                  className="form-control mb-4"
                  placeholder="Enter invitation ID"
                />
                <LoadingButton
                  loading={loading}
                  onClick={retrieve}
                  variant="contained"
                >
                  Get participant
                </LoadingButton>
              </div>
            </div>

            <Table striped borderless>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>ID</th>
                  <th>Organisation</th>
                </tr>
              </thead>
              <tbody>
                {accredited.map((c, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      {c.participant.firstName} {c.participant.surname}
                    </td>
                    <td>{c.otp}</td>
                    <td>{c.participant.organisation}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {participant && (
              <Modal
                size="lg"
                onHide={() => setParticipant(null)}
                show={participant}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Accredit Participant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="mb-3">
                    <small>Name</small>
                    <h4 style={{ fontWeight: 700 }}>
                      {participant.participant.title}{" "}
                      {participant.participant.firstName}{" "}
                      {participant.participant.surname}
                    </h4>
                  </div>
                  <div className="mb-3">
                    <small>Organisation</small>
                    <h4 style={{ fontWeight: 700 }}>
                      {participant.participant.organisation}
                    </h4>
                  </div>
                  <div className="mb-3">
                    <small>Email Address</small>
                    <h4 style={{ fontWeight: 700 }}>
                      {participant.participant.emailAddress}
                    </h4>
                  </div>{" "}
                  <div className="mb-3">
                    <small>Organisation</small>
                    <h4 style={{ fontWeight: 700 }}>
                      {participant.participant.code}
                      {participant.participant.phoneNumber}
                    </h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <LoadingButton
                    onClick={accreditParticipant}
                    loading={loading}
                    variant="contained"
                    color="error"
                  >
                    Accredit participant
                  </LoadingButton>
                </Modal.Footer>
              </Modal>
            )}
          </div>
          <div className="col-lg-5">
            <div className="mb-3">
              <small className="">Search participant</small>
            </div>
            <div className="col-lg-10">
              <input
                onChange={(e) => setSearchData(e.target.value)}
                type="text"
                className="form-control mb-4"
                placeholder="Search by email, name or phone number"
              />
              <LoadingButton
                loading={searching}
                onClick={searchParticipant}
                color="warning"
              >
                Get participant
              </LoadingButton>
            </div>
            <div>
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>ID</th>
                    <th>Organisation</th>
                  </tr>
                </thead>
                <tbody>
                  {searched.map((c, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        {c.participant.firstName} {c.participant.surname}
                      </td>
                      <td>{c.otp}</td>
                      <td>{c.participant.organisation}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accreditation;
