import React, { useEffect, useState } from "react";
import { httpService } from "../httpService";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ViewParticipant() {
  const { id } = useParams();
  const [response, setResponse] = useState(null);
  const getData = async () => {
    const { data } = await httpService(`participant/viewsingle/${id}`);

    if (data) {
      setResponse(data);
      console.log(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="mt-5">
        {response && (
          <div className="container">
            <div className="mb-5">
              <div className="mb-3">
                <Typography variant="caption">Participant</Typography>
                <Typography>
                  {response.participant.title} {response.participant.firstName}{" "}
                  {response.participant.surname}
                </Typography>
              </div>
              <div className="mb-3">
                <Typography variant="caption">Organisation</Typography>
                <Typography>{response.participant.organisation}</Typography>
              </div>
              <div className="mb-3">
                <Typography variant="caption">Attendance Mode</Typography>
                <Typography sx={{ textTransform: "uppercase" }}>
                  {response.participant.attendanceMode}
                </Typography>
              </div>
              <div className="mb-3">
                <Typography variant="caption">Attendance Mode</Typography>
                <Typography sx={{ textTransform: "lowercase" }}>
                  {response.participant.emailAddress}
                </Typography>
              </div>
            </div>
            <div>
              {response.approval && (
                <div>
                  <div className="mb-3">
                    <Typography variant="caption">Approval Status</Typography>
                    <Typography textTransform={"uppercase"}>
                      {response.approval.status}
                    </Typography>
                  </div>
                  <div className="mb-3">
                    <Typography variant="caption">Attendance Mode</Typography>
                    <Typography textTransform={"uppercase"}>
                      {response.approval.attendanceMode}
                    </Typography>
                  </div>
                  <div className="mb-3">
                    <Typography variant="caption">OTP</Typography>
                    <Typography textTransform={"uppercase"}>
                      {response.approval.otp}
                    </Typography>
                  </div>
                  <div>
                    <LoadingButton variant="contained">
                      DELETE REGISTRATION
                    </LoadingButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewParticipant;
