import {
  Accessible,
  AccountBox,
  Cancel,
  Flag,
  Mail,
  PeopleAlt,
  Person,
  Search,
} from "@mui/icons-material";
import {
  CardActionArea,
  Chip,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { httpService } from "../httpService";
import { Badge, Modal, Table } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { AlertContext } from "../context/AlertContext";
import { useNavigate } from "react-router-dom";

function ApprovalPage() {
  const [registrationData, setRegistrationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [caption, setCaption] = useState("");
  const [countries, setCountries] = useState([]);

  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const toggleShow = () => {
    setShow(!show);
  };

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService.get("participant/dashboard");
    if (data) {
      setRegistrationData(data);
    }
    setLoading(false);
  };

  const physical = async () => {
    setLoading(true);
    setCaption("Physical");
    const { data } = await httpService.get(
      "participant/dashboardv2?attendanceMode=physical"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  const virtual = async () => {
    setLoading(true);
    setCaption("Virtual");
    const { data } = await httpService.get(
      "participant/dashboardv2?attendanceMode=virtual"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  const total = async () => {
    setLoading(true);
    setCaption("Total");
    const { data } = await httpService.get("participant/dashboardv2");
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  const disabled = async () => {
    setLoading(true);
    setCaption("Disabled");
    const { data } = await httpService.get(
      "participant/dashboardv2?anyDisability=yes"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
    countryCount();
    const timer = setInterval(() => {
      getData();

      countryCount();
    }, 1000 * 60 * 2);

    return () => clearInterval(timer);
  }, []);

  const countryCount = async () => {
    setLoading(true);

    const { data } = await httpService.get("participant/countrycount");
    if (data) {
      setCountries(data);
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="mt-5 container">
        <div className="mb-4 text-center">
          <h4 style={{ fontWeight: 700 }}>JEOG 2024 REGISTRATION DASHBOARD</h4>
        </div>

        {registrationData && (
          <div className="row mb-4">
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="col-lg-3 p-0  "
            >
              <CardActionArea
                onClick={total}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Total Registered</p>
                  <h3>{registrationData.total}</h3>
                </div>
                <div>
                  <PeopleAlt sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#e3e3e3" }}
              className="col-lg-3 p-0"
            >
              <CardActionArea
                onClick={physical}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Physical</p>
                  <h3>{registrationData.physical}</h3>
                </div>
                <div>
                  <Person sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#ee6f57" }}
              className="col-lg-3 text-white  p-0"
            >
              <CardActionArea
                onClick={virtual}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Virtual</p>
                  <h3>{registrationData.virtual}</h3>
                </div>
                <div>
                  <AccountBox sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#cb3737" }}
              className="col-lg-3 text-white  p-0"
            >
              <CardActionArea
                onClick={disabled}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Disabled</p>
                  <h3>{registrationData.disabled}</h3>
                </div>
                <div>
                  <Accessible sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#be3144" }}
              className="col-lg-3 text-white  p-0"
            >
              <CardActionArea
                onClick={toggleShow}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Countries</p>
                  <h3>{countries.length}</h3>
                </div>
                <div>
                  <Flag sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
          </div>
        )}

        <div>
          <div className="col-lg-4">
            {loading && <LinearProgress />}
            <TextField
              fullWidth
              label="Search participant"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-3">
        <div className="mt-2 overflow-scroll">
          {caption && <Typography variant="caption">{caption}</Typography>}
          <Table striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Country</th>
                <th>Organisation</th>
                <th>Institution</th>
                <th>Role</th>
                <th>Attendance Mode</th>
                {/* <th>Registration Date</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {results.map((c, i) => (
                <tr
                  className={c.country !== "Nigeria" && "table-warning"}
                  key={i}
                >
                  <td>
                    <Button onClick={() => navigate(`/participant/${c._id}`)}>
                      {i + 1}
                    </Button>
                  </td>
                  <td className="text-capitalize">
                    {`${c.title}  ${c.firstName} ${c.surname}`}
                  </td>
                  <td className="text-capitalize">{c.country}</td>
                  <td className="text-capitalize">{c.organisation}</td>
                  <td>{c.institution}</td>
                  <td>{c.role}</td>
                  <td className="text-capitalize">
                    {c.attendanceMode === "physical" ? (
                      <Badge bg="success">physical</Badge>
                    ) : (
                      <Badge bg="warning">virtual</Badge>
                    )}
                  </td>
                  {/* <td>{new Date(c.dateOfRegistration).toDateString()}</td> */}
                  {c.attendanceMode === "physical" ? (
                    <Approval participant={c} />
                  ) : (
                    <VirtualParticipant participant={c} />
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal onHide={toggleShow} show={show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Registered Countries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Countries</th>
                <th>Numbers</th>
              </tr>
            </thead>
            <tbody>
              {countries.map((c, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className="text-capitalize">{c.country}</td>
                  <td>{c.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ApprovalPage;

function Approval({ participant }) {
  const [notification, setNotification] = useState(null);
  const getStatus = async () => {
    const { data } = await httpService(
      `approval/notification/${participant._id}`
    );

    if (data) {
      setNotification(data);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);
  const { setAlertData } = useContext(AlertContext);
  const [approving, setApproving] = useState(false);
  const approve = () => {
    Swal.fire({
      icon: "question",
      showCancelButton: true,
      title: "Approve Registration",
      text: "Are you sure you want to approve the registration for this participant?",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setApproving(true);
        const { data, error } = await httpService.post("approval/approve", {
          participant: participant._id,
          status: "approved",
        });

        if (data) {
          getStatus();
          setAlertData({ open: true, message: data, severity: "success" });
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setApproving(false);
      }
    });
  };

  const reject = () => {
    Swal.fire({
      icon: "question",
      showCancelButton: true,
      title: "Reject Registration",
      text: "Are you sure you want to reject the registration for this participant?",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setApproving(true);
        const { data, error } = await httpService.post("approval/reject", {
          participant: participant._id,
          status: "rejected",
        });

        if (data) {
          getStatus();
          setAlertData({ open: true, message: data, severity: "success" });
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setApproving(false);
      }
    });
  };
  return (
    <>
      <td>
        {notification ? (
          <>
            {notification.status === "approved" ? (
              <Chip
                label="Approved"
                color="success"
                icon={notification.messageDelivered ? <Mail /> : <Cancel />}
              />
            ) : (
              <Chip label="Rejected" color="error" />
            )}
          </>
        ) : (
          <Chip label={"Not contacted"} />
        )}
      </td>
      <td>
        <Stack direction={"row"}>
          <LoadingButton
            onClick={approve}
            loading={approving}
            sx={{ textTransform: "capitalize" }}
            disabled={notification}
          >
            Approve
          </LoadingButton>
          <LoadingButton
            disabled={notification}
            sx={{ textTransform: "capitalize" }}
            color="error"
            onClick={reject}
          >
            Reject
          </LoadingButton>
        </Stack>
      </td>
    </>
  );
}

function VirtualParticipant({ participant }) {
  const [notification, setNotification] = useState(null);
  const [approving, setApproving] = useState(false);
  const getStatus = async () => {
    setApproving(true);
    const { data } = await httpService(
      `approval/notification/${participant._id}`
    );

    if (data) {
      setNotification(data);
    }
    setApproving(false);
  };

  useEffect(() => {
    getStatus();
  }, []);
  const { setAlertData } = useContext(AlertContext);

  const approve = () => {
    Swal.fire({
      icon: "question",
      showCancelButton: true,
      title: "Approve Registration",
      text: "Are you sure you want to approve the registration for this participant?",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setApproving(true);
        const { data, error } = await httpService.post(
          "approval/virtualapproval",
          {
            participant: participant._id,
            status: "approved",
          }
        );

        if (data) {
          getStatus();
          setAlertData({ open: true, message: data, severity: "success" });
        }

        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setApproving(false);
      }
    });
  };

  return (
    <>
      <td>
        {notification ? (
          <>
            {notification.status === "approved" ? (
              <Chip
                label="Approved"
                color="success"
                icon={notification.messageDelivered ? <Mail /> : <Cancel />}
              />
            ) : (
              <Chip label="Rejected" color="error" />
            )}
          </>
        ) : (
          <Chip label={"Not contacted"} />
        )}
      </td>
      <td>
        <LoadingButton
          onClick={approve}
          loading={approving}
          disabled={notification}
          sx={{ textTransform: "capitalize" }}
        >
          send message
        </LoadingButton>
      </td>
    </>
  );
}
