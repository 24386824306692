import React from "react";
import { aimText, imagesAndTexts } from "../dataTexts";
import { Avatar } from "@mui/material";

function JeogAims() {
  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <div className="container pt-5 pb-5 mb-5">
        <div className="d-flex justify-content-end">
          <div className="col-lg-6 mb-5 border-bottom">
            <div className="d-none d-lg-block">
              <p className="ourAim">Our Aim</p>
              <p style={{ color: "#142d4c", fontSize: "20px" }}>{aimText}</p>
            </div>
            <div className="d-sm-block d-lg-none">
              <p className="ourAimMobile">Our Aim</p>
              <p style={{ color: "#142d4c", fontSize: "18px" }}>{aimText}</p>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block">
          <div className="d-flex justify-content-between">
            {imagesAndTexts.map((c) => (
              <div className="col-lg-3">
                <div className="d-flex justify-content-center">
                  <Avatar sx={{ height: 300, width: 300 }} src={c.img} />
                </div>
                <div
                  style={{ backgroundColor: "#233142" }}
                  className="mt-2 text-white p-3 rounded text-center"
                >
                  <h5>{c.text}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-sm-block d-lg-none">
          <div className="d-flex flex-wrap justify-content-center">
            {imagesAndTexts.map((c) => (
              <div className="col-lg-3 mb-5">
                <div className="d-flex justify-content-center">
                  <Avatar sx={{ height: 300, width: 300 }} src={c.img} />
                </div>
                <div
                  style={{ backgroundColor: "#233142" }}
                  className="mt-2 text-white p-3 rounded text-center"
                >
                  <h5>{c.text}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JeogAims;
