import { createContext } from "react";

export const AlertContext = createContext({});

export const ProcedureContext = createContext({});

export const ExaminationIdContext = createContext({});

export const RefreshContext = createContext({});

export const LockExaminationContext = createContext({});
