import React, { useContext, useState } from "react";

import {
  Alert,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  coeRoles,
  countriesAndCodes,
  institutions,
  jambDepartments,
  jambRoles,
  organisations,
  polytechnicRoles,
  states,
  titles,
  universityRoles,
} from "./countriesAndCodes";
import { ArrowForward, Flag } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { AlertContext } from "../context/AlertContext";
import Swal from "sweetalert2";
import { httpService } from "../httpService";
import { useNavigate } from "react-router-dom";

function RegistrationPage() {
  const [participant, setParticipant] = useState({});
  const [loading, setLoading] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [attendanceMode] = useState("virtual");

  const navigate = useNavigate();
  const { setAlertData } = useContext(AlertContext);
  const [otherOrganisation, setOtherOrganisation] = useState(false);
  const otherOrganisations = [
    "Tertiary Educational Institutions",
    "Regulatory Educational Bodies",
    "Ministry of Education",
    "Others",
  ];

  const disabilities = [
    "ADHD",
    "Albino",
    "Autism",
    "Blind",
    "Deaf",
    "Polio",
    "Others",
  ];

  const handleChange = (e) => {
    setParticipant({ ...participant, [e.target.name]: e.target.value });
  };

  const register = (e) => {
    e.preventDefault();
    if (!attendanceMode)
      return setAlertData({
        open: true,
        message: "Please indicate attendance mode",
        severity: "info",
      });
    if (!participant.country)
      return setAlertData({
        open: true,
        message: "Please select a country",
        severity: "info",
      });

    Swal.fire({
      icon: "question",
      title: "Register",
      text: "Do you want to submit your registration",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.post(
          "/participant/register",
          { ...participant, attendanceMode }
        );
        if (data) {
          navigate("/registrationsuccessful");
        }
        if (error)
          setAlertData({ open: true, message: error, severity: "error" });

        setLoading(false);
      }
    });
  };

  return (
    <div id="register">
      <div className="">
        <div className="mb-3">
          <p style={{ fontWeight: "700", fontSize: 30 }}> Register Here</p>
        </div>
        {/* <div className="mt-2 mb-2">
          <div className="col-lg-4">
            <Alert severity="info">
              <strong>
                Registration is open now and will be closed on the 6th of
                September 2024
              </strong>
            </Alert>
          </div>
        </div> */}
        <form onSubmit={register}>
          <div className="row">
            <div className="col-lg-6 mb-5">
              <div className="mb-4">
                <TextField
                  name="title"
                  onChange={handleChange}
                  select
                  fullWidth
                  label="Title"
                >
                  {titles.map((c, i) => (
                    <MenuItem key={i} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="mb-4">
                <TextField
                  name="firstName"
                  onChange={handleChange}
                  fullWidth
                  required
                  label="First Name"
                />
              </div>
              <div className="mb-4">
                <TextField
                  name="surname"
                  onChange={handleChange}
                  fullWidth
                  required
                  label="Surname"
                />
              </div>
              <div className="mb-4">
                <TextField
                  name="country"
                  onChange={(e) => {
                    setParticipant({
                      ...participant,
                      country: e.target.value.Country.trim(),
                      code: e.target.value.Code,
                    });
                  }}
                  select
                  fullWidth
                  required
                  label="Country"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Flag />
                      </InputAdornment>
                    ),
                  }}
                >
                  {countriesAndCodes.map((c, i) => (
                    <MenuItem key={i} value={c}>
                      {c.Country}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div className="col-lg-6 mb-5">
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  required
                  error={errorPhone}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {participant.code}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="mb-4">
              <TextField
                fullWidth
                label="Confirm phone Number"
                name="confirmPhoneNumber"
                onChange={handleChange}
                error={errorPhone}
                helperText={errorPhone ? "Phone number does not match" : ""}
                onBlur={(e) =>
                  setErrorPhone(participant.phoneNumber !== e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {participant.code}
                    </InputAdornment>
                  ),
                }}
              />
            </div> */}
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="Email Address"
                  name="emailAddress"
                  required
                  onChange={handleChange}
                  error={errorEmail}
                />
              </div>
              <div className="mb-4">
                <TextField
                  fullWidth
                  label="Confirm email Address"
                  name="confirmEmailAddress"
                  required
                  error={errorEmail}
                  onBlur={(e) =>
                    setErrorEmail(participant.emailAddress !== e.target.value)
                  }
                  helperText={errorEmail ? "Email address does not match" : ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mb-5">
              {participant.country === "Nigeria" ? (
                <>
                  <div className="mb-4">
                    <TextField
                      fullWidth
                      label="Organization"
                      name="organisation"
                      required
                      select
                      onChange={handleChange}
                    >
                      {organisations.map((c, i) => (
                        <MenuItem key={i} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  {/* univerity & polytechnic & coe */}
                  <div>
                    {(participant.organisation === "University" ||
                      participant.organisation === "Polytechnic" ||
                      participant.organisation === "College of Education" ||
                      participant.organisation ===
                        "Degree Awarding Institutions (Affliate)") && (
                      <>
                        <div className="mb-4">
                          <TextField
                            fullWidth
                            label="State"
                            name="state"
                            select
                            onChange={(e) => {
                              setParticipant({
                                ...participant,
                                state: e.target.value.ST_NAME,
                                stateId: e.target.value.ST_ID,
                                //category: e.target.value.category,
                                region: e.target.value.REGION,
                              });
                            }}
                          >
                            {states.map((c, i) => (
                              <MenuItem key={i} value={c}>
                                {c.ST_NAME}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="mb-4">
                          <TextField
                            fullWidth
                            label="Institution"
                            name="institution"
                            select
                            onChange={handleChange}
                          >
                            {institutions
                              .filter(
                                (c) =>
                                  c.InSt === participant.stateId &&
                                  c.category === participant.organisation
                              )
                              .map((c, i) => (
                                <MenuItem key={i} value={c.INName}>
                                  {c.INName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </div>
                        <div className="mb-4">
                          {participant.organisation === "University" && (
                            <>
                              <div className="mb-4">
                                <TextField
                                  fullWidth
                                  onChange={(e) => {
                                    if (e.target.value === "Others") {
                                      setParticipant({
                                        ...participant,
                                        otherRole: true,
                                      });
                                    } else {
                                      setParticipant({
                                        ...participant,
                                        role: e.target.value,
                                        otherRole: false,
                                      });
                                    }
                                  }}
                                  label="Role"
                                  select
                                >
                                  {universityRoles.map((c, i) => (
                                    <MenuItem value={c} key={i}>
                                      {c}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className="mb-4">
                                {participant.otherRole && (
                                  <TextField
                                    fullWidth
                                    label="Please specify your role"
                                    onChange={(e) =>
                                      setParticipant({
                                        ...participant,
                                        role: e.target.value,
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </>
                          )}
                          {participant.organisation === "Polytechnic" && (
                            <>
                              <div className="mb-4">
                                <TextField
                                  fullWidth
                                  onChange={(e) => {
                                    if (e.target.value === "Others") {
                                      setParticipant({
                                        ...participant,
                                        otherRole: true,
                                      });
                                    } else {
                                      setParticipant({
                                        ...participant,
                                        role: e.target.value,
                                        otherRole: false,
                                      });
                                    }
                                  }}
                                  label="Role"
                                  select
                                >
                                  {polytechnicRoles.map((c, i) => (
                                    <MenuItem value={c} key={i}>
                                      {c}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className="mb-4">
                                {participant.otherRole && (
                                  <TextField
                                    fullWidth
                                    label="Please specify your role"
                                    onChange={(e) =>
                                      setParticipant({
                                        ...participant,
                                        role: e.target.value,
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </>
                          )}
                          {participant.organisation ===
                            "College of Education" && (
                            <>
                              <div className="mb-4">
                                <TextField
                                  fullWidth
                                  onChange={(e) => {
                                    if (e.target.value === "Others") {
                                      setParticipant({
                                        ...participant,
                                        otherRole: true,
                                      });
                                    } else {
                                      setParticipant({
                                        ...participant,
                                        role: e.target.value,
                                        otherRole: false,
                                      });
                                    }
                                  }}
                                  label="Role"
                                  select
                                >
                                  {coeRoles.map((c, i) => (
                                    <MenuItem value={c} key={i}>
                                      {c}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className="mb-4">
                                {participant.otherRole && (
                                  <TextField
                                    fullWidth
                                    label="Please specify your role"
                                    onChange={(e) =>
                                      setParticipant({
                                        ...participant,
                                        role: e.target.value,
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  {/* JAMB */}
                  <div className="mb-4">
                    {participant.organisation === "JAMB" && (
                      <div>
                        <div className="mb-4">
                          <TextField
                            onChange={(e) =>
                              setParticipant({
                                ...participant,
                                department: e.target.value,
                              })
                            }
                            fullWidth
                            label="Department"
                            select
                          >
                            {jambDepartments.map((c, i) => (
                              <MenuItem value={c} key={i}>
                                {c}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="mb-4">
                          <TextField
                            onChange={(e) =>
                              setParticipant({
                                ...participant,
                                level: e.target.value,
                              })
                            }
                            select
                            fullWidth
                            label="Level"
                          >
                            {jambRoles.map((c, i) => (
                              <MenuItem key={i} value={c}>
                                {c}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="mb-4">
                          <TextField
                            fullWidth
                            label="ID Card"
                            onChange={(e) =>
                              setParticipant({
                                ...participant,
                                idCard: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="mb-4"></div>
                      </div>
                    )}
                  </div>

                  {/** */}

                  {/* other organisations */}
                  <div className="div">
                    {participant.organisation !== "University" &&
                      participant.organisation !== "Polytechnic" &&
                      participant.organisation !== "College of Education" &&
                      participant.organisation !== "JAMB" &&
                      participant.organisation !== "" && (
                        <div className="mb-4">
                          <TextField
                            label="Designation"
                            fullWidth
                            onChange={(e) =>
                              setParticipant({
                                ...participant,
                                role: e.target.value,
                              })
                            }
                          />
                        </div>
                      )}
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <TextField
                      select
                      label="Organization"
                      fullWidth
                      onChange={(e) => {
                        if (e.target.value === "Others") {
                          setOtherOrganisation(true);
                        } else {
                          handleChange(e);
                          setOtherOrganisation(false);
                        }
                      }}
                    >
                      {otherOrganisations.map((c, i) => (
                        <MenuItem value={c} key={i}>
                          {c}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  {otherOrganisation && (
                    <div className="mb-4">
                      <TextField
                        fullWidth
                        label="Organization"
                        name="organisation"
                        onChange={handleChange}
                        helperText="Kindly specify your organization"
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <TextField
                      fullWidth
                      label="Designation"
                      name="designation"
                      onChange={handleChange}
                      helperText="Kindly specify your designation"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-6 mb-5">
              <div className="mb-3">
                <TextField
                  name="anyDisability"
                  required
                  fullWidth
                  select
                  label="Any disability"
                  onChange={handleChange}
                >
                  <MenuItem value="yes">YES</MenuItem>
                  <MenuItem value="no">NO</MenuItem>
                </TextField>
              </div>

              {participant.anyDisability === "yes" && (
                <>
                  <div className="mb-3">
                    <TextField
                      name="disability"
                      required
                      fullWidth
                      select
                      label="Please specify disability"
                      onChange={(e) => {
                        if (e.target.value === "Others") {
                          setParticipant({
                            ...participant,
                            otherDisability: true,
                          });
                        } else {
                          handleChange(e);
                          setParticipant({
                            ...participant,
                            otherDisability: false,
                            disability: e.target.value,
                          });
                        }
                      }}
                    >
                      {disabilities.map((c, i) => (
                        <MenuItem key={i} value={c}>
                          {c.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {participant.otherDisability && (
                    <div className="mb-3">
                      <TextField
                        name="disability"
                        onChange={handleChange}
                        fullWidth
                        label="Input disability"
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <TextField
                      fullWidth
                      label="Will you be coming with a guide"
                      select
                      name="attendingWithAGuide"
                      onChange={handleChange}
                    >
                      <MenuItem value="yes">YES</MenuItem>
                      <MenuItem value="no">NO</MenuItem>
                    </TextField>
                  </div>
                </>
              )}
              <div className="mb-3">
                <TextField
                  fullWidth
                  label="Preferred Language"
                  select
                  name="language"
                  onChange={handleChange}
                >
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="arabic">Arabic</MenuItem>
                  <MenuItem value="french">French</MenuItem>
                </TextField>
              </div>
            </div>

            <div className="col-lg-6 mb-5">
              <div className="mt-3">
                <LoadingButton
                  color="error"
                  endIcon={<ArrowForward />}
                  type="submit"
                  disabled={errorEmail || errorPhone}
                  variant="contained"
                  loading={loading}
                >
                  Register
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegistrationPage;
