import {
  Accessible,
  AccountBox,
  Flag,
  PeopleAlt,
  Person,
  Search,
} from "@mui/icons-material";
import {
  CardActionArea,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { httpService } from "../httpService";
import { Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function RegistrationDashboard() {
  const [registrationData, setRegistrationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [caption, setCaption] = useState("");
  const [countries, setCountries] = useState([]);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  const getData = async () => {
    setLoading(true);
    const { data } = await httpService.get("participant/dashboard");
    if (data) {
      setRegistrationData(data);
    }
    setLoading(false);
  };

  const physical = async () => {
    setLoading(true);
    setCaption("Physical");
    const { data } = await httpService.get(
      "participant/dashboardv2?attendanceMode=physical"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  const virtual = async () => {
    setLoading(true);
    setCaption("Virtual");
    const { data } = await httpService.get(
      "participant/dashboardv2?attendanceMode=virtual"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  const total = async () => {
    setLoading(true);
    setCaption("Total");
    const { data } = await httpService.get("participant/dashboardv2");
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  const disabled = async () => {
    setLoading(true);
    setCaption("Disabled");
    const { data } = await httpService.get(
      "participant/dashboardv2?anyDisability=yes"
    );
    if (data) {
      setResults(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
    countryCount();
    const timer = setInterval(() => {
      getData();

      countryCount();
    }, 1000 * 60 * 2);

    return () => clearInterval(timer);
  }, []);

  const countryCount = async () => {
    setLoading(true);

    const { data } = await httpService.get("participant/countrycount");
    if (data) {
      console.log(data);
      setCountries(data);
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="mt-5 container">
        <div className="mb-4 text-center">
          <h4 style={{ fontWeight: 700 }}>JEOG 2024 REGISTRATION DASHBOARD</h4>
        </div>

        {registrationData && (
          <div className="row mb-4">
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="col-lg-3 p-0  "
            >
              <CardActionArea
                onClick={total}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Total Registered</p>
                  <h3>{registrationData.total}</h3>
                </div>
                <div>
                  <PeopleAlt sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#e3e3e3" }}
              className="col-lg-3 p-0"
            >
              <CardActionArea
                onClick={physical}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Physical</p>
                  <h3>{registrationData.physical}</h3>
                </div>
                <div>
                  <Person sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#ee6f57" }}
              className="col-lg-3 text-white  p-0"
            >
              <CardActionArea
                onClick={virtual}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Virtual</p>
                  <h3>{registrationData.virtual}</h3>
                </div>
                <div>
                  <AccountBox sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#cb3737" }}
              className="col-lg-3 text-white  p-0"
            >
              <CardActionArea
                onClick={disabled}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Disabled</p>
                  <h3>{registrationData.disabled}</h3>
                </div>
                <div>
                  <Accessible sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
            <div
              style={{ backgroundColor: "#be3144" }}
              className="col-lg-3 text-white  p-0"
            >
              <CardActionArea
                onClick={toggleShow}
                className="p-3 d-flex justify-content-between align-items-center  "
              >
                <div>
                  <p>Countries</p>
                  <h3>{countries.length}</h3>
                </div>
                <div>
                  <Flag sx={{ height: 50, width: 50 }} />
                </div>
              </CardActionArea>
            </div>
          </div>
        )}

        <div>
          <div className="col-lg-4">
            {loading && <LinearProgress />}
            <TextField
              fullWidth
              label="Search participant"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="mt-2 overflow-scroll">
          {caption && <Typography variant="caption">{caption}</Typography>}
          <Table striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Country</th>
                <th>Organisation</th>
                <th>Registration Date</th>
              </tr>
            </thead>
            <tbody>
              {results.map((c, i) => (
                <tr
                  className={c.country !== "Nigeria" && "table-warning"}
                  key={i}
                >
                  <td>{i + 1}</td>
                  <td className="text-capitalize">
                    {c.firstName} {c.surname}
                  </td>
                  <td className="text-capitalize">{c.country}</td>
                  <td className="text-capitalize">{c.organisation}</td>
                  <td>{new Date(c.dateOfRegistration).toDateString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal onHide={toggleShow} show={show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Registered Countries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped borderless>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Countries</th>
                <th>Numbers</th>
              </tr>
            </thead>
            <tbody>
              {countries.map((c, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className="text-capitalize">{c.country}</td>
                  <td>{c.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationDashboard;
