import React from "react";
import africa from "./africa.png";
import logo from "./logo.png";
import { Avatar, Button, Paper } from "@mui/material";
import { Badge } from "react-bootstrap";
import { ArrowForward } from "@mui/icons-material";
import RegistrationPage from "./RegistrationPage";
import ObjectiveSection from "./ObjectiveSection";
import JeogAims from "./JeogAims";
import JeogIntroduction from "./JeogIntroduction";
import JeogSubthemes from "./JeogSubthemes";
import JeogRational from "./JeogRational";
import jeog from "../images/jeog.jpeg";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  return (
    <div className="mb-5">
      <div className="">
        <div
          className="p-3 homeBanner d-flex align-items-center"

          //style={{ minHeight: "60vh", backgroundColor: "#fffdfb" }}
        >
          <div className="container">
            <div className="d-none d-lg-block">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <img src={logo} alt="logo" height={80} />
                  <div className="mt-2">
                    <h5 style={{ fontWeight: "900" }}>
                      JAMB EQUAL OPPORTUNITY GROUP (JEOG)
                    </h5>
                    <p>Presents</p>
                    <h4 style={{ wordSpacing: 2 }}>
                      1<sup>ST</sup> AFRICAN REGIONAL CONFERENCE ON EQUAL
                      OPPORTUNITY OF ACCESS TO HIGHER EDUCATION{" "}
                      <strong>(ARCEAHED)</strong>
                    </h4>
                    <hr />
                    <div>
                      <div className="mb-3">
                        <Badge bg="danger">THEME</Badge>
                      </div>
                      <h1
                        style={{
                          fontWeight: 900,
                          color: "GrayText",
                          textTransform: "uppercase",

                          fontFamily: "Abril Fatface",
                        }}
                      >
                        Advancing the potentials of persons with disabilities
                        (PWD) in educational & economic development
                        {/* ADVANCING THE POTENTIALS OF PERSONS WITH DISABILITIES (PWD) IN
                EDUCATIONAL & ECONOMIC DEVELOPMENT */}
                      </h1>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={africa} alt="logo" className="img-responsive" />
                </div>
              </div>
            </div>
            <div className="d-sm-block text-center d-lg-none">
              <div className="d-flex   align-items-center">
                <div>
                  <img src={logo} alt="logo" height={80} />
                  <div className="mt-2">
                    <h5 style={{ fontWeight: "900" }}>
                      JAMB EQUAL OPPORTUNITY GROUP (JEOG)
                    </h5>
                    <p>Presents</p>
                    <h4 style={{ wordSpacing: 2 }}>
                      1<sup>ST</sup> AFRICAN REGIONAL CONFERENCE ON EQUAL
                      OPPORTUNITY OF ACCESS TO HIGHER EDUCATION{" "}
                      <strong>(ARCEAHED)</strong>
                    </h4>
                    <hr />
                    <div>
                      <div className="mb-3">
                        <Badge bg="danger">THEME</Badge>
                      </div>
                      <h1
                        style={{
                          fontWeight: 900,
                          color: "GrayText",
                          textTransform: "uppercase",

                          fontFamily: "Abril Fatface",
                        }}
                      >
                        Advancing the potentials of persons with disabilities
                        (PWD) in educational & economic development
                        {/* ADVANCING THE POTENTIALS OF PERSONS WITH DISABILITIES (PWD) IN
                EDUCATIONAL & ECONOMIC DEVELOPMENT */}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-end mt-3">
              <Button
                variant="contained"
                color="error"
                endIcon={<ArrowForward />}
                href="#register"
                // onClick={() => navigate("/register")}
              >
                Register
              </Button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-center pt-5">
            <img src={jeog} className="img-fluid" alt="logo" />
          </div>
        </div>
        <div>
          <JeogIntroduction />
        </div>
        <div>
          <JeogSubthemes />
        </div>
        <div>
          <JeogRational />
        </div>
        <div className=" ">
          <ObjectiveSection />
          <div className="">
            <JeogAims />
          </div>
          <div className="container">
            <RegistrationPage />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
