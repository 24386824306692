import React from "react";
import { rationale } from "../dataTexts";

function JeogRational() {
  return (
    <div style={{}}>
      <div className="container pt-5 pb-5 mb-5">
        <div className="text-center">
          <div className="mb-5  ">
            <div className="d-none d-lg-block">
              <p className="ourAim">Rationale</p>
            </div>
            <div className="d-sm-block d-lg-none">
              <p className="ourAimMobile">Rationale</p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-center">
          <div
            style={{ backgroundColor: "#24527a", color: "white" }}
            className="col-lg-4 p-4  d-flex align-items-center justify-content-center  text-center mb-4"
          >
            <div>
              <h4>1.</h4>
              <p>{rationale.p1}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#e0ebeb", color: "#24527a" }}
            className="col-lg-4 p-4  d-flex align-items-center justify-content-center  text-center mb-4"
          >
            <div>
              <h4>2.</h4>
              <p>{rationale.p2}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JeogRational;
