import axios from "axios";

export const mcpdpuser = process.env.REACT_APP_PROJECT_USER;

export const loggedInUser = JSON.parse(localStorage.getItem(mcpdpuser)) || null;

const httpService = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_BACKEND_URL
      : `${window.location.origin.replace(":3002", ":5678")}/api/`,
  timeout: 15000,
  //withCredentials: "include",
  headers: {
    "Content-Type": "application/json",
    accountid: loggedInUser ? loggedInUser._id : "",
  },
});

httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      await httpService.post("auth/refreshtoken", {
        id: loggedInUser._id,
      });
      return httpService(error.config);
    }

    if (error.response)
      return { error: error.response.data, status: error.response.status };
    return { error: "Cannot establish network connection" };
  }
);
const logout = async () => {
  const res = await httpService.get("auth/logout");
  if (res) {
    localStorage.removeItem(mcpdpuser);
    window.location.assign("/");
  }
};
export { httpService, logout };
