import React from "react";
import { objectivesTexts } from "../dataTexts";
import { Stack } from "@mui/material";

function ObjectiveSection() {
  return (
    <div style={{ minHeight: "60vh", backgroundColor: "#393e46" }}>
      <div className="container text-white p-5">
        <div className="mb-5">
          <h3 style={{ fontWeight: "700" }}>Conference Objectives</h3>
        </div>

        <div className="row">
          {objectivesTexts.map((c, i) => (
            <ObjectiveCard key={i} text={c} index={i} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ObjectiveSection;

function ObjectiveCard({ text, index }) {
  return (
    <div
      className="col-lg-5 me-3 mb-3 p-3 d-flex align-items-center"
      style={{ backgroundColor: "#f95959" }}
    >
      <Stack spacing={2} direction={"row"}>
        <div className="d-flex align-items-center  p-3 border-end">
          <h1 style={{ fontFamily: "Abril Fatface", fontWeight: 700 }}>
            {index + 1}.
          </h1>
        </div>
        <div>
          <p style={{ fontSize: 20 }}>{text}</p>
        </div>
      </Stack>
    </div>
  );
}
