import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { httpService } from "../httpService";
import logo from "../pages/logo.png";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { jambDepartments, jambRoles } from "./countriesAndCodes";
import { LoadingButton } from "@mui/lab";
import { AlertContext } from "../context/AlertContext";
import Swal from "sweetalert2";

function JambStaffDataUpdate() {
  const { id } = useParams();
  const [staff, setStaff] = useState(null);
  const [loading, setLoading] = useState(false);

  const { setAlertData } = useContext(AlertContext);

  const getStaff = async () => {
    setLoading(true);
    const { data, error } = await httpService(`participant/view/${id}`);

    if (data) {
      setStaff(data);
    }

    if (error) {
    }
    setLoading(false);
  };

  useEffect(() => {
    getStaff();
  }, []);

  const navigate = useNavigate();

  const updateMyprofile = async (e) => {
    e.preventDefault();

    Swal.fire({
      icon: "question",
      title: "Update Profile",
      text: "Are you sure you want to update your registration",
      showCancelButton: true,
      cancelButtonText: "NO",
      confirmButtonText: "YES",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { data, error } = await httpService.patch(
          `participant/updateparticipant/${id}`,
          staff
        );

        if (data) {
          navigate("/registrationsuccessful");
        }
        if (error) {
          setAlertData({ open: true, message: error, severity: "error" });
        }
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <div className="mt-5 container">
        <div className="text-center mb-4">
          <img src={logo} alt="logo" height={100} />

          <div className="mt-3 text-muted">
            <h6 style={{ fontWeight: 700 }}>JAMB STAFF REGISTRATION UPDATE</h6>
          </div>
          {loading && <CircularProgress size={15} />}
        </div>
        {staff && (
          <div className="col-lg-4">
            <div className="mb-4">
              <h2>{`${staff.title} ${staff.surname} ${staff.firstName}`}</h2>
            </div>
            <div className="alert alert-info border-0 mb-4">
              <p>
                <strong>This update is strictly for Jamb Staff</strong>
              </p>
              <p>Please enter your Department, Level and ID Card number</p>
            </div>
            <form onSubmit={updateMyprofile}>
              <div className="mb-4">
                <TextField
                  required
                  onChange={(e) =>
                    setStaff({
                      ...staff,
                      department: e.target.value,
                    })
                  }
                  fullWidth
                  label="Department"
                  select
                  value={staff.department}
                >
                  {jambDepartments.map((c, i) => (
                    <MenuItem value={c} key={i}>
                      {c}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="mb-4">
                <TextField
                  required
                  onChange={(e) =>
                    setStaff({
                      ...staff,
                      level: e.target.value,
                    })
                  }
                  select
                  fullWidth
                  label="Level"
                  value={staff.level}
                >
                  {jambRoles.map((c, i) => (
                    <MenuItem key={i} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="mb-4">
                <TextField
                  required
                  fullWidth
                  label="ID Card"
                  onChange={(e) =>
                    setStaff({
                      ...staff,
                      idCard: e.target.value,
                    })
                  }
                  value={staff.idCard}
                />
              </div>
              <div>
                <LoadingButton
                  type="submit"
                  loading={loading}
                  variant="contained"
                >
                  Update my profile
                </LoadingButton>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default JambStaffDataUpdate;
