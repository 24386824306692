import img1 from "./images/img1.png";

import img2 from "./images/img2.png";
import img3 from "./images/img3.png";
import img4 from "./images/img4.png";

export const objectivesTexts = [
  "To assess progress of African countries in implementing Agenda 2063 and SDG 4 with regard to equal opportunity of access to higher education especially for persons with special needs particularly the blind, deaf, persons with Albinism, autism, Down’s Syndrome, physically challenged and persons in correctional centers",
  "To conduct national comparisons/share experiences on modalities of admission and retention of persons with disabilities in Africa; and",
  "To propose sustainable solutions for increased access of students with special needs to quality higher  education.",
];
export const aimText =
  "Our aim is to improve access to higher education for Persons with Disabilities (PWD) and to improve the learning environment in tertiary institutions for such persons";

export const rationale = {
  p1: "The Global Partnership for Education estimates that 90% of children with disabilities in low and lower middle income countries do not go to school",
  p2: "The need to ensure all eligible candidates in Africa have access to higher education regardless of their disabilities to meet the current and prevailing global trends of inclusiveness, leave no one behind, every child counts.",
};

export const subThemes = [
  "SDG 4: Inclusive and Equitable Quality Education and Lifelong Learning.",
  "Comparative Analysis of legislative provisions forspecial needs persons in African countries.",
  "Towards a Regional Africa Model for Ensuring EqualOpportunity/Access to Higher Education of the Special needs Populace.",
  "Basic Education as requisite for Access to higher education for special needs persons in Africa.",
  "UNCRPD 24: Education – Accessibility to inclusive andQuality Education",
  "Strategies for Accessibility to Quality and EqualEducation for Learners with Disabilities.",
];

export const introductions = [
  "In 2017, the Registrar of the Joint Admissions and Matriculation Board (JAMB), Professor Is-haq O. Oloyede, CON,FNAL established the JAMB Equal Opportunity Group (JEOG) under the chairmanship of distinguished Professor Peter A. Okebukola, OFR with the aim of ensuring that all eligible Nigerians have the same chances, options and benefits in participating in the Unified Tertiary Matriculation Examination (UTME) and are not discriminated against, for reason of any form of disability.",
  "In furtherance of this noble objective a National Conference with the theme “Towards Increasing Equal Opportunity of Access to Higher Education in Nigeria” was held in Abuja between 24th September and 27th September 2023. The conference attracted a large audience with over five hundred critical stakeholders’ participation.",
  `The forthcoming ARCEAHED which is themed "Advancing the Potentials of Persons with Disabilities (PWD) in Educational and Economic Development" is aimed at assessing the progress of African countries in implementing the African Union Agenda 2063 and the United Nations Sustainable Development Goal 4.`,
  "The conference will provide a platform to share experiences on the modalities for admission and retention of persons with disabilities and propose practical solutions to enhance access to quality higher education for students with special needs in African countries.",
];
export const imagesAndTexts = [
  { img: img1, text: "Opportunity" },
  { img: img2, text: "Inclusivity" },
  { img: img4, text: "Equality" },
];
