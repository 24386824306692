import React from "react";
import { introductions } from "../dataTexts";

function JeogIntroduction() {
  return (
    <div style={{ backgroundColor: "" }}>
      <div className="container pt-5 pb-5 mb-5">
        <div className="">
          <div className="d-none d-lg-block">
            <div className="col-lg-6 mb-5 border-bottom">
              <p className="ourAim">Introduction</p>
            </div>
          </div>
          <div className="d-sm-block d-lg-none">
            <div className="col-lg-6 mb-5 border-bottom">
              <p className="ourAimMobile">Introduction</p>
            </div>
          </div>
        </div>
        {introductions.map((c, i) => (
          <p key={i} className="mb-3">
            {c}
          </p>
        ))}
      </div>
    </div>
  );
}

export default JeogIntroduction;
